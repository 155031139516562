import React from 'react';

import Layout from '../../components/Layout';

export default function MitEinerSpende() {
	return (
		<Layout title="Mit einer Spende helfen">
			<h2>Mit einer Spende helfen</h2>
			<p>Sie möchten die Arbeit der Bereitschaft Bad Reichenhall finanziell unterstützen?</p>
			<p>Sehr gerne nehmen wir Ihre Spende mit einer Überweisung entgegen!</p>
			<h4>Unsere Bankverbindung</h4>
			<p>
				Sparkasse Berchtesgadener Land
				<br />
				Bankleitzahl: 710 500 00
				<br />
				Konto-Nr.: 737
			</p>
			<p>
				IBAN: DE16 710 500 00 000 000 0737
				<br />
				BIC: BYLADEM1BGL
			</p>
			<h4>Wichtig: Verwendungszweck</h4>
			<p>
				Als Verwendungszweck geben Sie bei der Überweisung bitte folgendes an:{' '}
				<b>Bereitschaft Bad Reichenhall</b>
			</p>
		</Layout>
	);
}
